import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import AdminDeviceAssignmentModal from "../../components/admin-device-assignment/AdminDeviceAssignmentModal";
import Linkage from "../../components/link/Linkage";
import Title from "../../components/title/Title";
import { useAdminDevice } from "../../hooks/admin-device/useAdminDeviceHooks";
import DownloadFileButton from "../../components/download-file-button/DownloadFileButton";
import { useEffect } from "react";
import { useIsMobile } from "../../hooks/media-hooks/mediaHooks";
import { pathToTenantDevice } from "../../utilities/paths";

const AdminDeviceView = () => {
  const navigate = useNavigate();
  const mobile = useIsMobile()
  const { adminDeviceId } = useParams();
  const { adminDevice: device, fetchAdminDevice } =
    useAdminDevice(adminDeviceId);

  const handleDeviceAssigned = fetchAdminDevice;

  useEffect(() => {
console.log(device);

    //Redirect if this device has been assigned to a tenant
    if (device?.location?.tenant?.identifier && device?.serialNumber)
      navigate(
        pathToTenantDevice(device?.location?.tenant?.identifier, device?.serialNumber)
      );
  }, [device, navigate]);

  if (device === null)
    return (
      <>
      </>
    );

  return (
    <Grid container margin={2} flexDirection="column">
      <Grid item>
        <Linkage to={`/devices`}>
          <Typography>
            <ArrowBackIcon fontSize="small" /> All devices
          </Typography>
        </Linkage>
      </Grid>
      <Title>Device information</Title>
      <Grid container flexDirection="column" alignContent="center">
        <Grid item width={mobile? "100%": 400} margin={10}>
          <Table aria-label="simple table">
            <TableBody>
              <AdminDeviceInfo
                name="SerialNumber"
                value={device.serialNumber}
              />
              <AdminDeviceInfo
                name="Certificate expiry date"
                value={new Date(
                  device.certificateExpirationDate
                ).toLocaleDateString()}
              />
              <AdminDeviceInfo
                name="Tenant"
                value={device.tenant?.name ?? "NOT ASSIGNED"}
                bold={!device.tenant}
              />

              <AdminDeviceInfo
                name="Device id"
                value={device.serialNumber ?? ""}
                link={`/${device.tenant?.identifier}/device/${device.serialNumber}`}
              />
            </TableBody>
          </Table>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "1em",
            }}
          >
            <AdminDeviceAssignmentModal
              device={device}
              onAssignment={handleDeviceAssigned}
            />
            <DownloadFileButton
              serialNumber={device.serialNumber}
              onDownloadFailed={(err) =>
                alert("Could not download QR code. Call for help")
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

type adminDeviceInfoProps = {
  name: string;
  value: string;
  bold?: boolean;
  link?: string;
};
const AdminDeviceInfo = ({
  name,
  value,
  bold = false,
  link,
}: adminDeviceInfoProps) => {
  return (
    <TableRow>
      <TableCell>
        <Typography>{name}</Typography>
      </TableCell>
      <TableCell>
        {!!link ? (
          <Linkage to={link}>
            <Typography
              textAlign={"right"}
              sx={(theme) => ({
                fontWeight: bold
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular,
              })}
            >
              {value}
            </Typography>
          </Linkage>
        ) : (
          <Typography
            textAlign={"right"}
            sx={(theme) => ({
              fontWeight: bold
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular,
            })}
          >
            {value}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default AdminDeviceView;
