import {  Button, Grid, InputAdornment, TextField } from "@mui/material";
import { DetailedDevice } from "../../models/detailedDevice.model";
import { useFluidTextFieldUpdate } from "../../hooks/fluid-hooks/fluidHooks";

type props = {
  device: DetailedDevice;

  reloadDetails: () => void;
};
const FluidStep = ({ device, reloadDetails }: props) => {

  const onUpdated = () => {}

  const {
    input,
    newFluid: fluid,
    fluidValueInvalid,
    handleTextFieldChange,
    updateFluid,
  } = useFluidTextFieldUpdate(
    device.serialNumber,
    device.fluid.percentLeft,
    onUpdated
  );



  const handleSave = () => {
    updateFluid();
  };


  const handleRefill = () => {
    updateFluid(100);
  };

  return (
    <Grid container flexDirection={"column"} alignItems="center" spacing={2}>
      <Grid item>
        Current level is <b>{device.fluid.percentLeft.toFixed(2)} %</b>
      </Grid>
      <Grid item>
        <Button
        variant="outlined"
          onClick={handleRefill}
        >
          Register new bottle
        </Button>
      </Grid>

      <Grid item>
        or
      </Grid>

      <Grid item>
        <TextField
          inputMode="decimal"
          onChange={handleTextFieldChange}
          placeholder="30.51"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          error={fluidValueInvalid && input.length > 0}
          helperText={
            fluidValueInvalid && input.length > 0
              ? "Invalid entry"
              : "Enter new level as a percentage"
          }
        />
      </Grid>
      <Grid item>
        <Button
          onClick={handleSave}
          disabled={
            device.fluid.percentLeft === fluid || fluidValueInvalid || input.length === 0
          }
        >
          Save
        </Button>
      </Grid>

    </Grid>
  );
};

export default FluidStep;
