import axios, { AxiosError } from "axios";
import {
  AdminDevice,
  CreateAdminDeviceDto,
  Device,
  Firmware,
  FirmwareVersion,
  Tenant,
  UpdateAdminDevice,
  UpdatePowerSource,
} from "../../../models";
import {
  API_URL,
  IPaginationQuery,
  LocationQuery,
  PagedResponse,
  PaginationQuery,
} from "../../api";
import { X_TENANT_IDENTIFIER } from "..";
import { DetailedDevice } from "../../../models/detailedDevice.model";
import { CreateComment, DeviceComment, UpdateComment } from "../../../models/deviceComment.model";

interface CommonHeaders {
  [key: string]: string | number | boolean;
}

export const getTenantByIdentifier = async (
  identifier: string
): Promise<Tenant | null> => {
  try {
    let response = await axios.get(`${API_URL}/tenants/${identifier}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getTenants = async (
  paginationQuery: PaginationQuery,
  searchTerm?: string,
  locationQuery?: LocationQuery
): Promise<PagedResponse<Tenant> | null> => {
  try {
    let params: { [index: string]: any } = {};

    if (!!searchTerm) {
      params["term"] = searchTerm;
    }

    if (!!locationQuery) {
      params["longitude"] = locationQuery.longitude;
      params["latitude"] = locationQuery.latitude;
      params["orderByProximity"] = locationQuery.orderByProximity;
    }
    let response = await axios.get(paginationQuery.toUrl("/tenants"), {
      params,
    });
    return response.data as PagedResponse<Tenant>;
  } catch (error) {
    return null;
  }
};

export const getClosestTenant = async (
  locationQuery: LocationQuery
): Promise<Tenant | null> => {
  try {
    let params: { [index: string]: any } = {};

    if (!!locationQuery) {
      params["longitude"] = locationQuery.longitude;
      params["latitude"] = locationQuery.latitude;
    }
    let response = await axios.get(`${API_URL}/tenants/closest`, {
      params,
    });
    return response.data as Tenant;
  } catch (error) {
    return null;
  }
};

export const getAdminDevices = async (
  paginationQuery: IPaginationQuery,
  searchTerm: string = ""
): Promise<PagedResponse<AdminDevice> | null> => {
  try {
    let params = { term: searchTerm };

    let response = await axios.get(paginationQuery.toUrl(`/devices`), {
      params,
      transformRequest: (data, headers) => {
        delete (headers?.common as unknown as CommonHeaders)[
          X_TENANT_IDENTIFIER
        ];

        return data;
      },
    });
    return response.data as PagedResponse<AdminDevice>;
  } catch (error) {
    return null;
  }
};

export const getSimpleDeviceById = async (
  serialNumber: string
): Promise<Device | null> => {
  try {
    let response = await axios.get(API_URL + `/devices/${serialNumber}`);
    return response.data as Device;
  } catch (error) {
    return null;
  }
};

export const getAdminDeviceById = async (
  serialNumber: string
): Promise<DetailedDevice | null> => {
  try {
    let response = await axios.get(API_URL + `/devices/${serialNumber}`);
    return response.data as DetailedDevice;
  } catch (error) {
    return null;
  }
};
export const createDevice = async (
  device: CreateAdminDeviceDto
): Promise<AdminDevice | null> => {
  try {
    var response = await axios.post(`${API_URL}/devices`, device);
    return response.data as AdminDevice;
  } catch (error) {
    return null;
  }
};

export const updateDevice = async (
  device: UpdateAdminDevice
): Promise<number> => {
  try {
    var response = await axios.put(
      `${API_URL}/devices/${device.serialNumber}/assign`,
      device
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const updatePowerSource = async (
  serialNumber: string,
  dto: UpdatePowerSource
): Promise<number> => {
  try {
    var response = await axios.put(
      `${API_URL}/devices/${serialNumber}/power-source`,
      dto
    );
    return response.status;
  } catch (error) {
    console.error(error);
    return (error as AxiosError).response?.status ?? 500;
  }
};

export const getDeviceComments = async (
  serialNumber: string,
  paginationQuery: PaginationQuery
): Promise<PagedResponse<DeviceComment> | null> => {
  try {
    var response = await axios.get(
      paginationQuery.toUrl(`/devices/${serialNumber}/comments`)
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const postComment = async (
  serialNumber: string,
  comment: CreateComment
): Promise<DeviceComment | null> => {
  try {
    var response = await axios.post(
      API_URL + `/devices/${serialNumber}/comments`,
      comment
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};



export const updateComment = async (
  serialNumber: string,
  commentId:string,
  comment: UpdateComment
): Promise<DeviceComment | null> => {
  try {
    var response = await axios.put(
      API_URL + `/devices/${serialNumber}/comments/${commentId}`,
      comment
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const deleteComment = async (
  serialNumber: string,
  commentId:string,
): Promise<boolean> => {
  try {
     await axios.delete(
      API_URL + `/devices/${serialNumber}/comments/${commentId}`    );
    return true;
  } catch (error) {
    return false;
  }
};


export const getFirmware = async (
  serialNumber: string
): Promise<Firmware | null> => {
  try {
    let response = await axios.get(
      API_URL + `/devices/${serialNumber}/firmware`
    );
    return response.data as Firmware;
  } catch (error) {
    return null;
  }
};

export const getM0FirmwareVersions = async (): Promise<
  FirmwareVersion[] | null
> => {
  try {
    let response = await axios.get(API_URL + `/firmware-versions/m0-versions`);
    return response.data as FirmwareVersion[];
  } catch (error) {
    return null;
  }
};

export const updateM0FirmwareForDevice = async (
  serialNumber: string,
  newFirmwareVersion: string
): Promise<number> => {
  try {
    var response = await axios.post(
      `${API_URL}/devices/${serialNumber}/firmware/m0`,
      { newVersion: newFirmwareVersion }
    );
    return response.status;
  } catch (error) {
    console.error(error);
    let resError = error as AxiosError;
    return resError.response?.status ?? 500;
  }
};

export const downloadQrCode = async (serialNumber: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/devices/${serialNumber}/qr-code`,
      {
        responseType: "blob",
      }
    );

    return response.data as Blob;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getdeviceCertificate = async (serialNumber: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/devices/${serialNumber}/certificate`,
      {
        responseType: "blob",
      }
    );

    return response.data as Blob;
  } catch (error) {
    return null;
  }
};
